import * as React from "react"
import { Link } from "gatsby"

export default function Breadcrumbs({currentPage}) {
  return (
    <div className="breadcrumbs">
      <div className="container">
        <ul>
          <li><span className="current">{currentPage}</span></li>
          <li><Link className="page-redirect" to="/">Početna</Link></li>
        </ul>
      </div>
    </div>
  )
}

import * as React from "react"
import Layout from "../components/Layout"
import Breadcrumbs from "../components/Breadcrumbs"
import BHLogo from "../images/symbol-original.png"
import ContactForm2 from "../components/ContactForm2"
import { SEO } from "../components/seo"
import Map, { Marker, NavigationControl } from 'react-map-gl/maplibre'
import 'maplibre-gl/dist/maplibre-gl.css'

const isClient = typeof window !== 'undefined'

const KontaktPage = () => {
  return (
    <>
      <Layout
        backArrow={true}
        bodyClasses={" flat-menu-page -white-third-bg meny-right "}
      >
        <Breadcrumbs currentPage="Kontakt" />
        <main className="main-content">
          <div className="container">

            <h1 className="title-of-page">Kontakt</h1>

            <section className="main-contacts-section">
    {/*
              <div className="row contacts-description">
                <div className="col-12 col-md-10 col-xl-7">
                  <div className="description-text">
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                  </div>
                </div>
              </div>
              */}

              <div className="contacts-list-block -bottom-indent">
                <div className="row">
                  <div className="col-12 col-sm-6 col-xl-3">
                    <div className="contacts-list-block-item">
                      <h3 className="small-title">Adresa</h3>
                      <ul className="contacts-list">
                        <li>
                          <span className="icon-arrows contacts-list-icon"></span>
                          <a href="https://www.google.com/maps/place/Barrel+House/@44.8218149,20.4654594,17z/data=!4m14!1m8!3m7!1s0x475a7ab619392363:0x476f640a40f91dcc!2s%C5%BDor%C5%BEa+Klemansoa+19,+Beograd+11000!3b1!8m2!3d44.8221345!4d20.4685278!10e3!3m4!1s0x475a7b8fb992c3fb:0x3688917e4e981e5d!8m2!3d44.8221755!4d20.4688047" target="_blank" rel="noreferrer">Žorža Klemansoa 19, Beograd</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-xl-3">
                    <div className="contacts-list-block-item">
                      <h3 className="small-title">Telefoni</h3>
                      <ul className="contacts-list">
                        <li>
                          <span className="icon-telephone contacts-list-icon"></span>
                          Za rezervacije i događaje: <a href="tel:00381604466039">060/44-66-039</a>
                        </li>
                        <li>
                          <span className="icon-telephone contacts-list-icon"></span>
                          Za venčanja: <a href="tel:00381606990070">060/699-00-70</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-xl-3">
                    <div className="contacts-list-block-item">
                      <h3 className="small-title">Email</h3>
                      <ul className="contacts-list">
                        <li>
                          <span className="icon-envelope contacts-list-icon"></span>
                          <a href="mailto:office@barrelhouse.rs">office@barrelhouse.rs</a>
                        </li>
                        <li>
                          <span className="icon-envelope contacts-list-icon"></span>
                          <a href="mailto:vencanja@barrelhouse.rs">vencanja@barrelhouse.rs</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-xl-3">
                    <div className="contacts-list-block-item">
                      <h3 className="small-title">Društvene mreže</h3>
    {/*<!-- SOCIAL ICONS -->*/}
                      <ul className="social-icons -lg -black">
                        <li><a href="https://www.instagram.com/barrelhousebelgrade/" target="_blank" rel="noreferrer"><span className="icon-social_instagram"></span></a></li>
                        <li><a href="https://www.facebook.com/barrelhousebg" target="_blank" rel="noreferrer"><span className="icon-social_facebook"></span></a></li>
                        <li><a href="https://www.tripadvisor.com/Restaurant_Review-g294472-d17813343-Reviews-Barrel_House-Belgrade.html" target="_blank" rel="noreferrer"><span className="icon-social_tripadvisor"></span></a></li>
                      </ul>
    {/*<!-- SOCIAL ICONS END -->*/}
                    </div>
                  </div>
                </div>
              </div>

    {/*<!-- GOOGLE MAP -->*/}
              <div className="map-container -bottom-indent">
                <div className="map-wrap">
    {/*
                  <div id="map"></div>
                  <span className="icon-full-screen map-icon"></span>
                  */}

                    { isClient && (
                      <Map
                        initialViewState={{
                          longitude: 20.468796018872233,
                          latitude: 44.82232853241051,
                          zoom: 16
                        }}
                        mapStyle="https://maps.geoapify.com/v1/styles/klokantech-basic/style.json?apiKey=0d5a20e42aa14d5cb8f5ce33a97d292b"
                        attributionControl={false}
                      >
                        <Marker
                          longitude={20.468796018872233}
                          latitude={44.82232853241051}
                          color="red"
                        >
                          <div className="marker-wrapper">
                            <img alt="Barrel house logo" src={BHLogo} />
                          </div>
                        </Marker>
                        <NavigationControl />
                      </Map>
                    )}

                </div>
              </div>
    {/*<!-- GOOGLE MAP END -->*/}

            </section>

            <section className="contacts-form-section">
              <h3 className="title-section -md text-center">Pošaljite nam poruku</h3>
              <div className="row justify-content-center">
                <div className="col-12 col-sm-10 col-lg-8">
                  <ContactForm2 />
                </div>
              </div>
    {/*
              <div className="subscribe-wrap -bottom-indent">
                <h3 className="title-section -md">Subscribe newsletter</h3>
                <form className="contacts--cube__subscribe">
                  <div className="form-item-wrap">
                    <input id="email__subscribe" type="email" />
                    <label for="email__subscribe">
                      <span className="placeholder">Email</span>
                    </label>
                    <span className="bar"></span>
                  </div>
                  <input id="email-subscribe-icon" type="submit" />
                  <label className="submit-icon" for="email-subscribe-icon">
                    <span className="icon-send-button"></span>
                  </label>
                </form>
              </div>
            */}
            </section>

          </div>

        </main>
      </Layout>
    </>
  )
}

export default KontaktPage

export const Head = () => (
  <SEO />
)
